<template>
    <div v-if="showModal" class="kita-or-schule-modal modal" style="display: block">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h3 class="modal-content__title">Bitte wähle eine Kategorie</h3>
                <p class="modal-content__info" style="display:none;">So findest du genau passende Angebote. Und die Karte bleibt übersichtlch. Du kannst deine Auswahl jederzeit über den Filter ändern.</p>

                <div class="bildungs-cats categories">
                    <div class="row">
                        <div class="col-12">
                            <button class="no-btn btn-kita" title="Auf gehts zu Wolles Welt, der Plattform für alles rund um die Kinderbetreuung" @click="goToLink"ref="focusMe">
                                <div class="card bg-purple no-margin">
                                    <div class="card__img wolle">
                                        <img src="/assets/wolle.jpeg" alt="Kita-Finder">
                                    </div>
                                    <div class="card__content">
                                        <h3>Hier geht's zu Wolles Welt</h3>
                                        <p>Ratzfatz zum Betreuungsplatz.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn btn-schule" @click="selectedCard(categories[1])">
                                <div class="card bg-orange no-margin">
                                    <div class="card__img">
                                        <img src="/assets/icons/schul-finder.png" alt="Schulfinder">
                                    </div>
                                    <div class="card__content">
                                        <h3>Schulfinder</h3>
                                        <p>Finde eine passende Schule.</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "KitaOrSchuleModal",
        components: {
            KategorieCard: () => import('@/components/cards/Kategorie.vue'),
        },
        data: () => ({
            showModal: true,
            categories: [
            {
                label: 'Kitafinder',
                value: 'kita',
            },
            {
                label: 'Schulfinder',
                value: 'schule',
            },
            ],
        }),
        mounted(){
            setTimeout(() => {
                this.$refs.focusMe.focus();
            }, 2000);
        },
        methods: {
            selectedCard(type){
              this.$emit("selectedCard",type);
              this.showModal = false;
          },
          goToLink(){
            window.open("https://kitas.stadt.wolfsburg.de/", '_blank').focus();
        },
    }
};
</script>
<style lang="scss" >
    @import '@/scss/_variables.scss';

    .kita-or-schule-modal{

        .modal-dialog{
           @media (max-width: 420px) {
            padding: 10px;
            max-width: 100%!important;
        }
    }


    .modal-content__title{
       @media (max-width: 420px) {
        font-size: 16px!important;
    }
}

.modal-content__info{
   @media (max-width: 420px) {
    font-size: 13px!important;
}

}

.card__content{

 @media (max-width: 420px) {
    padding: 0 10px;
}

h3{
 @media (max-width: 420px) {
    font-size: 14px!important;
}
}

p{
   @media (max-width: 420px) {
    font-size: 12px!important;
}
}


}

.card__img{
   @media (max-width: 420px) {
    min-width: 100px!important;
}

img{
   @media (max-width: 420px) {
    height: 50%!important;
}
}
}

}

.bildungs-cats{
    .card {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 125px;
        height: auto;
        background-color: $primary;
        vertical-align: middle;
        box-shadow: 1px 2px 4px 0px #00000040;
        margin-bottom: 15px;
        border: none;
        border-radius: 0;
        width: 100%;

        &__img {
            background: $white-color;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 125px;
            height: 125px;

            img {
                height: 65%;
                width: auto;
            }

            &.wolle{
                img{
                    height: 100%;
                }
            }

        }

        &__content {
            padding: 0 15px;
            color: $white-color;

            h3 {
                color: $white-color;
                margin-bottom: 10px;
                font-size: 16px;
            }

            p {
                font-size: 13px;
            }
        }
    }
}

.no-margin {
    margin-bottom: 0 !important;
}

.btn-kita {
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
}

.btn-schule {
    text-align: left;
    width: 100%;
}
</style>